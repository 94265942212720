import { useSelector } from 'react-redux';
import {
  Button,
  Caret,
  DropdownMenu,
  Pen,
  Subtitle,
} from '../../components/shared';
import { defaultDateFormat } from '../../util/toggleSecondMillisecond';
import { IOysterLineDetail } from '../../entities/farms.entities';
import { IOysterCycle } from '../../entities/growing.entities';
import { amountDays } from '../../lib/farm.helpers';
import { formatNumber } from '../../entities/util-functions';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';
import { Spin } from 'antd';
import {
  SelectIsEditable,
  SelectIsFarmable,
  SelectIsGrower,
} from '../../store/extra/extra.selector';
import { labelNumber, labelRange } from '../../lib/common.helpers';
import { useMemo } from 'react';

interface Props {
  lineData: IOysterLineDetail;
  cyclePart: IOysterCycle | null;
  isPrevEnabled: boolean;
  isNextEnabled: boolean;
  onClickPrevGroup: () => void;
  onClickNextGroup: () => void;
  showEditLineModal: () => void;
  showEditSeedModal: () => void;
  onSeedingClick: () => void;
  onAssessmentClick: () => void;
  onCatchSpatClick: () => void;
  onHarvestClick: () => void;
  onMaintenanceClick: () => void;
  onExtraSeedClick: () => void;
  onFloatingManageClick(): void;
  checkedHistoryView: number;
  onHistoryViewClick: () => void;
}

const FlupsyOysterLineMobile = ({
  cyclePart,
  lineData,
  isPrevEnabled,
  isNextEnabled,
  onClickPrevGroup,
  onClickNextGroup,
  showEditLineModal,
  showEditSeedModal,
  onSeedingClick,
  onAssessmentClick,
  onCatchSpatClick,
  onHarvestClick,
  onMaintenanceClick,
  onExtraSeedClick,
  onFloatingManageClick,
  checkedHistoryView,
  onHistoryViewClick,
}: Props) => {
  const lang = useSelector(selectLang);
  const isEditable = SelectIsEditable(lineData.farm_id);
  const isFarmable = SelectIsFarmable(lineData.farm_id);
  const isGrower = SelectIsGrower(lineData.farm_id);
  const mainSeed = cyclePart?.main_seed,
    lastAssessment =
      cyclePart && cyclePart.assessments.length > 0
        ? cyclePart.assessments.reduce(
            (p, c) => (p.assessment_date < c.assessment_date ? c : p),
            cyclePart.assessments[0],
          )
        : null;
  const growingBaskets = lineData.growing_cycle
    ? lineData.growing_cycle.current_basket_count
    : 0;

  const { totalHarvested, totalSeeded, totalStorage } = useMemo(
    () => ({
      totalHarvested: lineData.growing_cycle?.total_harvested_amount ?? 0,
      totalSeeded: lineData.growing_cycle?.total_seeded_amount ?? 0,
      totalStorage: lineData.growing_cycle?.total_storage ?? 0,
    }),
    [lineData],
  );

  const basketSum =
    cyclePart?.seedings.reduce((p, c) => p + c.basket_count, 0) ?? 0;

  const lastHarvest =
    cyclePart && cyclePart.harvests.length > 0
      ? cyclePart?.harvests.reduce((p, c) =>
          p.complete_date > c.complete_date ? p : c,
        )
      : null;

  function getNewestDate() {
    const timestamps = [
        lastHarvest?.complete_date,
        lastAssessment?.assessment_date,
        mainSeed?.planned_date_seed,
      ].filter(date => date !== undefined && date !== null) as number[];
      console.log(timestamps);
      if (timestamps.length === 0) {
          return undefined;
      }

      return Math.max(...timestamps);
  }

  const lastActivityDate = getNewestDate();

  return (
    <>
      <div className='pt-16 pb-4'>
        <div className={'table-mobile__card'} onKeyDown={() => undefined}>
          {isEditable && (
            <div className={'table-mobile__card-dots'}>
              <DropdownMenu
                data={lineData}
                onEdit={showEditLineModal}
                column='isFarm'
                isRedirect={`/farms/${lineData.farm_id}`}
              />
            </div>
          )}
          <div className='d-flex justify-content-between align-items-center'>
            <Subtitle size={5} color='black-3' align='left' fontWeight={600}>
              {translate(lang, 'Line - %s', lineData.line_name)}
            </Subtitle>
          </div>
          <div className='pt-16'>
            <div className='d-flex pb-23'>
                          <div className='flex-basis-50'>
                              <Subtitle
                                size={3}
                                color='black'
                                align='left'
                                fontWeight={400}
                              >
                                {translate(lang, 'Date seeded')}
                              </Subtitle>
                              <>
                              {defaultDateFormat(
                                lineData.growing_cycle?.main_seed.planned_date_seed,
                                '-',
                              )}
                              </>
                          </div>
                          <div className='flex-basis-50 ml-24'>
                            <Subtitle
                              size={3}
                              color='black'
                              align='left'
                              fontWeight={400}
                            >
                              {translate(
                                lang,
                                mainSeed?.is_growing
                                  ? 'Planned harvest date'
                                  : 'Harvested date',
                              )}
                            </Subtitle>
                            <Subtitle size={4} color='black' align='left' fontWeight={500}>
                              {defaultDateFormat(
                                lastAssessment?.planned_date_harvest ??
                                  mainSeed?.planned_date_harvest,
                                '-',
                              )}
                            </Subtitle>
                          </div>
            </div>
            <div className='d-flex pb-23'>
              <div className='flex-basis-100'>
                <Subtitle
                    size={3}
                    color='black'
                    align='left'
                    fontWeight={400}
                  >
                    {translate(lang, 'Batch size')}
                  </Subtitle>
                  <Subtitle size={4} color='black' align='left' fontWeight={500}>
                  {totalStorage !== 0 ?
                    <>
                      [{lineData.growing_cycle?.main_seed?.spat_storage?.id}] {lineData.growing_cycle?.main_seed?.spat_storage?.source}
                      - {`${formatNumber(
                            (totalSeeded / totalStorage) * 100,
                      )}%`}
                    </> : '-'}
                  </Subtitle>
              </div>
            </div>
            <div className='d-flex pb-23'>
              <div className='flex-basis-100'>
                <Subtitle
                  size={3}
                  color='black'
                  align='left'
                  fontWeight={400}
                >
                  {translate(lang, 'Batch left in Flupsy')}
                </Subtitle>
                <Subtitle size={4} color='black' align='left' fontWeight={500}>
                  {totalSeeded !== 0 ?
                  <>
                  {`${formatNumber(
                          (totalHarvested / totalSeeded) * 100,
                  )}%`} [{totalSeeded} - {totalHarvested} {translate(lang, 'moved to farm')}]
                  </> : '-'}
                </Subtitle>
              </div>
            </div>
            <div className='d-flex pb-23'>
              <div className='flex-basis-50'>
                <Subtitle
                  size={3}
                  color='black'
                  align='left'
                  fontWeight={400}
                  className='mb-4'
                >
                  {translate(lang, 'Stage')}
                </Subtitle>
                <Subtitle size={4} color='black' align='left' fontWeight={500}>
                      {basketSum > 0 &&
                        cyclePart?.seedings.map((x, i) => (
                          <div key={i}>
                            {`${formatNumber(
                              (x.basket_count / basketSum) * 100,
                            )}% ${translate(lang, 'seed stage') } ${i + 1}`}
                          </div>
                        ))}
                      {
                        cyclePart?.harvests.map((x, i) => 
                          {
                            if (x.line_length) {
                              return (
                                <div key={i}>
                                  {`${formatNumber(
                                    (x.line_length  / basketSum) * 100)
                                  }% ${translate(lang, 'harvest stage') }  ${i + 1}`}
                                </div>
                              )
                            }
                          }
                        )
                      }
                    </Subtitle>
              </div>
              <div className='flex-basis-50 ml-24'>
                <Subtitle
                    size={3}
                    color='black'
                    align='left'
                    fontWeight={400}
                    className='mb-4'
                  >
                    {translate(
                      lang,
                      'Last checked',
                    )}
                  </Subtitle>
                  <Subtitle size={4} color='black' align='left' fontWeight={500}>
                    {lastActivityDate !== undefined ? defaultDateFormat(lastActivityDate, '-') : '-'}
                  </Subtitle>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='white-card pt-16 pl-12 pb-16 pr-12 mb-8'>
        <div className='d-flex justify-content-between'>
          <div className='w-100 d-flex justify-content-between align-items-center  white-card-small pt-3 pb-3'>
            <Button
              color='blue'
              size={3}
              width='default'
              type='transparent'
              className='mr-26'
              name='prev'
              onClick={onClickPrevGroup}
              disabled={!isPrevEnabled}
              onlyIconDisabled
            >
              <Caret color='#5A607F' direction='left' />
            </Button>
            <Subtitle
              size={4}
              color='black'
              align='left'
              fontWeight={500}
              disabled={!mainSeed}
            >
              {mainSeed?.season_name ?? 'Empty'}
            </Subtitle>
            <Button
              color='blue'
              size={3}
              width='default'
              type='transparent'
              className='ml-26'
              name='next'
              onClick={onClickNextGroup}
              disabled={!isNextEnabled}
              onlyIconDisabled
            >
              <Caret color='#5A607F' direction='right' />
            </Button>
          </div>
          <Button
            color='blue'
            size={0}
            width='default'
            type='bordered'
            className='ml-12'
            iconOnly
            onClick={showEditSeedModal}
            disabled={!isEditable || !mainSeed?.is_growing}
          >
            <Pen />
          </Button>
        </div>
        <div className='pt-16'>
          <>
            {mainSeed ? (
              <div className='d-flex flex-direction-col'>
                <button
                  className={`timeline-button${
                    checkedHistoryView === 1 ? ' --checked' : ''
                  }`}
                  onClick={onHistoryViewClick}
                >
                  {checkedHistoryView === -1 ? (
                    <div className='ml-17 mr-17'>
                      <Spin />
                    </div>
                  ) : (
                    translate(
                      lang,
                      checkedHistoryView === 1
                        ? 'Hide history'
                        : 'Show history',
                    )
                  )}
                </button>
                {mainSeed.is_growing ? (
                  <>
                    <Button
                      className='mb-8 mt-8'
                      color='blue'
                      size={1}
                      width='wide'
                      type='fill'
                      onClick={onFloatingManageClick}
                      disabled={!isFarmable}
                    >
                      {translate(lang, 'Inventory Management')}
                    </Button>
                    <Button
                      color='blue'
                      size={1}
                      width='wide'
                      type='fill'
                      onClick={onAssessmentClick}
                      disabled={!isFarmable}
                    >
                      {translate(lang, 'Add assessment')}
                    </Button>
                    {!mainSeed.is_catch_spat && (
                      <Button
                        className='mt-8'
                        color='blue'
                        size={1}
                        width='wide'
                        type='fill'
                        onClick={onExtraSeedClick}
                        disabled={!isFarmable}
                      >
                        {translate(lang, 'Add extra seed')}
                      </Button>
                    )}
                    <Button
                      className='mt-8'
                      color='green'
                      size={1}
                      width='wide'
                      type='fill'
                      onClick={onMaintenanceClick}
                      disabled={!isFarmable}
                    >
                      {translate(lang, 'Add maintenance')}
                    </Button>
                    <Button
                      color='blue'
                      size={1}
                      width='wide'
                      type='bordered'
                      className='mt-8'
                      onClick={onHarvestClick}
                      disabled={!isFarmable}
                    >
                      {translate(lang, 'Harvest Complete')}
                    </Button>
                  </>
                ) : (
                  <span></span>
                )}
              </div>
            ) : (
              <>
                {!isGrower && (
                  <Button
                    color='blue'
                    size={1}
                    width='wide'
                    type='fill'
                    onClick={onCatchSpatClick}
                    disabled={!isFarmable}
                  >
                    {translate(lang, 'Catch Spat')}
                  </Button>
                )}
                <Button
                  className='mt-10'
                  color='blue'
                  size={1}
                  width='wide'
                  type='fill'
                  onClick={onSeedingClick}
                  disabled={!isFarmable}
                >
                  {translate(lang, 'Seed the line')}
                </Button>
                <div className='d-flex justify-content-center align-items-center mt-10'>
                  <Subtitle
                    size={5}
                    color='black'
                    align='left'
                    fontWeight={400}
                  >
                    {translate(lang, 'Line empty for')}
                  </Subtitle>
                  <Subtitle
                    size={5}
                    color='black'
                    align='left'
                    fontWeight={600}
                    className='ml-4 mr-27'
                  >
                    {amountDays(lineData?.line_idle)}
                  </Subtitle>
                </div>
              </>
            )}
          </>
        </div>
      </div>
    </>
  );
};

export default FlupsyOysterLineMobile;