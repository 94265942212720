import { useCallback, useEffect, useState } from 'react';
import { Table, Spin, Modal } from 'antd';
import {
  Button,
} from '../../components/shared';
import ImageGallery from 'react-image-gallery';
import { translate } from '../../lib/lang.helper';
import { useWidth } from '../../util/useWidth';
import { selectLang } from '../../store/ui/ui.selector';
import { CaretDownOutlined } from '@ant-design/icons';
import { checkRolePermission } from '../../entities/util-functions';
import { sendSingleRequest } from '../../apis';
import { selectProfile } from '../../store/auth/auth.selector';
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import './styles.scss';
interface Line {
  line_name: string;
  spat_source: string;
  date_seed: number;
  stage: string;
  seed_type: string;
  line_length?: number;
  drop?: number;
  basket_count?: number;
  spacing: string;
  assessment_date?: number;
  size_min: number;
  size_max: number;
  size_avg: number;
  density: string;
  comment: string;
}
interface Farm {
  id: string | number;
  type: string;
  owners?: string;
  name: string;
}
interface DataItem {
  farm: Farm;
  lines: Line[];
}
interface IGalleryImage {
original: string;
thumbnail: string;
}
const Spats = () => {
  const width = useWidth();
  const history = useHistory();
  const [visibleImport, setVisibleImport] = useState(false);
  const [data, setData] = useState<DataItem[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [expandedRowKeys, setExpandedRowKeys] = useState<(string | number)[]>([]);
  const [visibleGallery, setVisibleGallery] = useState(false);
    const [gImages, setGImages] = useState<IGalleryImage[]>([]);
  const profile = useSelector(selectProfile);
  const lang = useSelector(selectLang);
  const fetchData = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await sendSingleRequest({}, 'GET', 'api/overview/spats-data', true);
      if (response.status) {
        setData(response.data);
      } else {
        setError('Failed to fetch data. Please try again later.');
      }
    } catch (err) {
      setError('An error occurred while fetching data.');
    } finally {
      setLoading(false);
    }
  }, []);
  useEffect(() => {
    fetchData();
  }, [fetchData]);
  const handleExpand = (expanded: boolean, record: DataItem) => {
    if (expanded) {
      setExpandedRowKeys([record.farm.id]); // Keep only the current row expanded
    } else {
      setExpandedRowKeys([]); // Collapse all rows
    }
  };
  const farmColumns = [
    {
      title: 'Farm',
      dataIndex: ['farm', 'type'],
      key: 'type',
      render: (text: string, record: DataItem) => (
        <>
          <span>{record.farm.name}[{record.farm.id}]</span>
          {record.farm.owners && <span>{` - Owned by ${record.farm.owners}`}</span>}
        </>
      ),
    },
  ];
  const openImages = (images: string[]) => {
    setVisibleGallery(true);
    setGImages(
      images.map(image => ({
        original: image,
        thumbnail: image,
      })),
    );
  };
  const lineColumns = [
    { title: 'Line', dataIndex: 'line_name', key: 'line_name' },
    { title: 'Spat Code', dataIndex: 'spat_source', key: 'spat_source' },
    { title: 'Date Seeded', dataIndex: 'date_seed', key: 'date_seed', render: (date: number) => new Date(date).toLocaleDateString() },
    { title: 'Stage', dataIndex: 'stage', key: 'stage' },
    { title: 'Type', dataIndex: 'seed_type', key: 'seed_type' },
    { title: 'Meters', dataIndex: 'line_length', key: 'line_length', render: (value: number) => value ?? 'N/A' },
    { title: 'Drop Depth', dataIndex: 'drop', key: 'drop', render: (value: number) => value ?? 'N/A' },
    { title: 'Containers', dataIndex: 'basket_count', key: 'basket_count', render: (value: number) => value ?? 'N/A' },
    { title: 'Spacing', dataIndex: 'spacing', key: 'spacing' },
    { title: 'Date Assessed', dataIndex: 'assessment_date', key: 'assessment_date', render: (date: number) => date ? new Date(date).toLocaleDateString() : '' },
    { title: 'Size Min', dataIndex: 'size_min', key: 'size_min' },
    { title: 'Size Max', dataIndex: 'size_max', key: 'size_max' },
    { title: 'Size Avg', dataIndex: 'size_avg', key: 'size_avg' },
    { title: 'Density', dataIndex: 'density', key: 'density' },
    { title: 'Comment', dataIndex: 'comment', key: 'comment' },
    {
        key: 'images',
        title: translate(lang, 'Photo'),
        render: (x: any) =>
        x.images && x.images.length > 0 ? (
            <div
            className='btn__modal'
            onKeyDown={() => undefined}
            onClick={openImages.bind(this, x.images)}
            >
            {translate(lang, 'View')}
            </div>
        ) : (
            <></>
        ),
    },
  ];

  const getLineColumns = (type:string, lineCol:any) => {
    if (type === 'MUSSEL') {
        return lineCol.filter((column: any) => column.dataIndex !== 'basket_count');
    }
    return lineCol;
  }
  return (
    <div className="h-calc-80 bg-secondary">
      <div className="container pos-relative">
        <div style={{paddingTop:'40px'}}>
          {checkRolePermission(
            { deniedRoles: ['processing_plant'] },
            profile?.role,
          ) && (
            <div>
              <Button
                size={width > 768 ? 3 : 5}
                type='fill'
                width='small'
                color='blue'
                onClick={() => history.push('/farms')}
              >
                {translate(lang, 'All farms')}
              </Button>
              <Button
                size={width > 768 ? 3 : 5}
                type='fill'
                width='small'
                color='blue'
                className={width > 768 ? 'ml-7' : 'mt-7'}
                onClick={() => history.push('/lines')}
              >
                {translate(lang, 'All Lines')}
              </Button>
              <Button
                size={width > 768 ? 3 : 5}
                type='fill'
                width='small'
                color='blue'
                className={width > 768 ? 'ml-7' : 'mt-7'}
                onClick={() => history.push('/farms/visual-mode')}
              >
                {translate(lang, 'Farms map')}
              </Button>
              {profile?.account_type === 'grower' ? (
                <Button
                  size={width > 768 ? 3 : 5}
                  type='bordered'
                  width='small'
                  color='green'
                  className={width > 768 ? 'ml-7' : 'mt-7'}
                  onClick={() => setVisibleImport(true)}
                >
                  {translate(lang, 'Import')}
                </Button>
              ) : (
                <Button
                  size={width > 768 ? 3 : 5}
                  type='fill'
                  width='small'
                  color='blue'
                  className={width > 768 ? 'ml-7' : 'mt-7'}
                  onClick={() => history.push('/line-spats')}
                >
                  {translate(lang, 'Spat count')}
                </Button>
              )}
            </div>
          )}
        </div>
        {loading ? (
          <div style={{ padding: '40px 0', textAlign: 'center' }}>
            <Spin size="large" />
          </div>
        ) : error ? (
          <div style={{ padding: '40px 0', textAlign: 'center', color: 'red' }}>
            {error}
          </div>
        ) : (
          <div style={{ padding: '45px 0' }}>
            <div
              style={{
                textAlign: 'center',
                fontSize: '20px',
                color: '#2F5496',
                marginBottom: '5px',
              }}
            >
              Spat Data By Farms
            </div>
            <Table
              className="table table--isSpats"
              rowKey={(record) => record.farm.id}
              pagination={false}
              columns={farmColumns}
              dataSource={data}
              expandable={{
                expandedRowRender: (record) => (
                  <Table
                    className="table table--isLines table--isLinesSpats"
                    rowKey="line_name"
                    columns={getLineColumns(record.farm.type,lineColumns)}
                    dataSource={record.lines}
                    pagination={false}
                  />
                ),
                expandIcon: ({ expanded, onExpand, record }) => (
                  <div
                    className="pt-20 pb-20"
                    onClick={(event) => {
                      event.stopPropagation();
                      onExpand(record, event);
                    }}
                  >
                    <CaretDownOutlined rotate={expanded ? 180 : 0} />
                  </div>
                ),
                expandedRowKeys, // Controlled expanded rows
                onExpand: handleExpand, // Custom expand handler
              }}
            />
          </div>
        )}
      </div>
      {visibleGallery && (
          <Modal
            title={translate(lang, 'Photos')}
            centered
            visible={visibleGallery}
            onOk={() => setVisibleGallery(false)}
            onCancel={() => setVisibleGallery(false)}
            width={1000}
          >
            <ImageGallery items={gImages} />
          </Modal>
        )}
    </div>
  );
};
export default Spats;