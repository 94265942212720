import { Modal } from 'antd';
import { TBusinessType } from '../../entities/general.entities';
import { translate } from '../../lib/lang.helper';
import { useDispatch, useSelector } from 'react-redux';
import { selectLang } from '../../store/ui/ui.selector';
import { selectAccount } from '../../store/auth/auth.selector';
import { CheckboxButton } from '../shared';
import { useState } from 'react';
import { updateAccountSetting } from '../../store/auth/auth.actions';

const musselColumns = [
    'Line',
    'Length',
    'Date seeded',
    'Planned harvest date',
    'Seed type',
    'Last assessment date',
    'Avg.Size',
    'Line group',
    'Batch seeded',
    'Days growth',
    'Inventory',
    'Est amount',
    'Photo',
    'Comment',
  ],
  oysterColumns = [
    'Line',
    'Length',
    'Date seeded',
    'Last assessment date',
    'Avg.Size',
    'Inventory',
    'Number of baskets',
    'Days growth',
    'Est amount',
    'Photo',
    'Batch seeded',
    'Comment',
  ],
  seaweedColumns = ['Line', 'Length', 'Date seeded'];

interface Props {
  type: TBusinessType;
  visible: boolean;
  onClose: () => void;
}

const LineColumnModifyModal = ({ type, visible, onClose }: Props) => {
  const dispatch = useDispatch<any>();
  const lang = useSelector(selectLang);
  const accSetting = useSelector(selectAccount);
  const musselHidden = accSetting?.mussel_line_hidden_columns ?? [],
    oysterHidden = accSetting?.oyster_line_hidden_columns ?? [],
    seaweedHidden = accSetting?.seaweed_line_hidden_columns ?? [];

  const musselOrder = accSetting?.mussel_line_columns_order ?? [],
    oysterOrder = accSetting?.oyster_line_columns_order ?? [],
    seaweedOrder = accSetting?.seaweed_line_columns_order ?? [];

  const initialColumns =
    type === 'MUSSEL'
      ? musselOrder.length > 0 ? musselOrder : musselColumns 
      : type === 'OYSTER'
      ? oysterOrder.length > 0 ? oysterOrder : oysterColumns
      : seaweedOrder.length > 0 ? seaweedOrder : seaweedColumns;

  const [checked, setChecked] = useState(
    type === 'MUSSEL'
      ? musselHidden
      : type === 'OYSTER'
      ? oysterHidden
      : seaweedHidden,
  );

  if (type === 'MUSSEL') {
    musselColumns.forEach(column => {
      if (!initialColumns.includes(column)) {
        initialColumns.push(column);
      }
    });
  }
  if (type === 'OYSTER') {
    oysterColumns.forEach(column => {
      if (!initialColumns.includes(column)) {
        initialColumns.push(column);
      }
    });
  }
  if (type === 'SEAWEED') {
    seaweedColumns.forEach(column => {
      if (!initialColumns.includes(column)) {
        initialColumns.push(column);
      }
    });
  }

  const [tableColumns, setTableColumns] = useState(initialColumns);

  const toggleCheck = (name: string, c: boolean) => {
    if (name === 'Line') return; 
    if (c) {
      setChecked(checked.filter(x => x !== name));
    } else if (!checked.includes(name)) {
      setChecked([...checked, name]);
    }
  };
  const confirmClick = async () => {
    const columns = tableColumns.filter(x => checked.includes(x));
    let data: any = {};
    if (type === 'MUSSEL') {
      data = { mussel_line_hidden_columns: columns, mussel_line_columns_order:tableColumns };
    } else if (type === 'OYSTER') {
      data = { oyster_line_hidden_columns: columns, oyster_line_columns_order:tableColumns };
    } else {
      data = { seaweed_line_hidden_columns: columns, seaweed_line_columns_order:tableColumns };
    }
    const r = await dispatch(updateAccountSetting(data));
    if (r !== true) {
      window.alert(r ?? 'Server error');
    } else {
      onClose();
    }
  };

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
  };

  const handleDrop = (index: number) => {
    return (e: React.DragEvent) => {
      const draggedIndex = parseInt(e.dataTransfer.getData('text/plain'), 10);
      if (draggedIndex === index) return;
      const newOrder = [...tableColumns];
      const [draggedItem] = newOrder.splice(draggedIndex, 1);
      newOrder.splice(index, 0, draggedItem);
      setTableColumns(newOrder);
    };
  };

  const handleDragStart = (index: number) => {
    return (e: React.DragEvent) => {
      e.dataTransfer.setData('text/plain', String(index));
    };
  };

  return (
    <Modal
      title={translate(lang, 'Modify columns')}
      visible={visible}
      onCancel={onClose}
      onOk={confirmClick}
      closable
    >
      <div className='wrap pl-12 pr-12'>
        {tableColumns.map((x, i) => (
          <div key={i} 
            draggable
            onDragStart={handleDragStart(i)}
            onDragOver={handleDragOver}
            onDrop={handleDrop(i)}
            className='mb-17'>
            <CheckboxButton
              label={translate(lang, x)}
              checked={!checked.includes(x)}
              disabled={x === 'Line'} 
              onChange={e => toggleCheck(x, e.target.checked)}
            />
          </div>
        ))}
      </div>
    </Modal>
  );
};

export default LineColumnModifyModal;
