import {
  IMusselLineDetail,
  IOysterLineDetail,
  ISeaweedLineDetail,
} from '../../entities/farms.entities';
import { TBusinessType } from '../../entities/general.entities';
import {
  IMusselCycle,
  IOysterCycle,
  ISeaweedCycle,
} from '../../entities/growing.entities';
import MusselLineMobile from './MusselLineMobile';
import OysterLineMobile from './OysterLineMobile';
import SeaweedLineMobile from './SeaweedLineMobile';
import FlupsyMusselLineMobile from './FlupsyMusselLineMobile';
import FlupsyOysterLineMobile from './FlupsyOysterLineMobile';

interface Props {
  type: TBusinessType;
  lineData: IMusselLineDetail | IOysterLineDetail | ISeaweedLineDetail;
  cyclePart?: IMusselCycle | IOysterCycle | ISeaweedCycle | null;
  isPrevEnabled: boolean;
  isNextEnabled: boolean;
  onClickPrevGroup: () => void;
  onClickNextGroup: () => void;
  showEditLineModal: () => void;
  showEditSeedModal: () => void;
  onSeedingClick: () => void;
  onAssessmentClick: () => void;
  onCatchSpatClick: () => void;
  onHarvestClick: () => void;
  onMaintenanceClick: () => void;
  onFloatingManageClick: () => void; // only for MUSSEL
  onExtraSeedClick: () => void;
  checkedHistoryView: number; // only for MUSSEL
  onHistoryViewClick: () => void; // only for MUSSEL
}

const LineTemplateMobile = ({
  type,
  lineData,
  cyclePart,
  isPrevEnabled,
  isNextEnabled,
  onClickPrevGroup,
  onClickNextGroup,
  showEditLineModal,
  showEditSeedModal,
  onSeedingClick,
  onAssessmentClick,
  onCatchSpatClick,
  onHarvestClick,
  onMaintenanceClick,
  onFloatingManageClick,
  onExtraSeedClick,
  checkedHistoryView,
  onHistoryViewClick,
}: Props) => (
  lineData.farm_is_flupsy ? (<>
    {type === 'MUSSEL' && (
      <FlupsyMusselLineMobile
        lineData={lineData as IMusselLineDetail}
        cyclePart={cyclePart as IMusselCycle}
        isPrevEnabled={isPrevEnabled}
        isNextEnabled={isNextEnabled}
        onClickPrevGroup={onClickPrevGroup}
        onClickNextGroup={onClickNextGroup}
        showEditLineModal={showEditLineModal}
        showEditSeedModal={showEditSeedModal}
        onSeedingClick={onSeedingClick}
        onAssessmentClick={onAssessmentClick}
        onCatchSpatClick={onCatchSpatClick}
        onHarvestClick={onHarvestClick}
        onMaintenanceClick={onMaintenanceClick}
        onFloatingManageClick={onFloatingManageClick}
        onExtraSeedClick={onExtraSeedClick}
        checkedHistoryView={checkedHistoryView}
        onHistoryViewClick={onHistoryViewClick}
      />
    )}

    {type === 'OYSTER' && (
      <FlupsyOysterLineMobile
        lineData={lineData as IOysterLineDetail}
        cyclePart={cyclePart as IOysterCycle}
        isPrevEnabled={isPrevEnabled}
        isNextEnabled={isNextEnabled}
        onClickPrevGroup={onClickPrevGroup}
        onClickNextGroup={onClickNextGroup}
        showEditLineModal={showEditLineModal}
        showEditSeedModal={showEditSeedModal}
        onSeedingClick={onSeedingClick}
        onAssessmentClick={onAssessmentClick}
        onCatchSpatClick={onCatchSpatClick}
        onHarvestClick={onHarvestClick}
        onMaintenanceClick={onMaintenanceClick}
        onExtraSeedClick={onExtraSeedClick}
        onFloatingManageClick={onFloatingManageClick}
        checkedHistoryView={checkedHistoryView}
        onHistoryViewClick={onHistoryViewClick}
      />
    )}
    
    </>) : 
    <>
    {type === 'MUSSEL' ? (
      <MusselLineMobile
        lineData={lineData as IMusselLineDetail}
        cyclePart={cyclePart as IMusselCycle}
        isPrevEnabled={isPrevEnabled}
        isNextEnabled={isNextEnabled}
        onClickPrevGroup={onClickPrevGroup}
        onClickNextGroup={onClickNextGroup}
        showEditLineModal={showEditLineModal}
        showEditSeedModal={showEditSeedModal}
        onSeedingClick={onSeedingClick}
        onAssessmentClick={onAssessmentClick}
        onCatchSpatClick={onCatchSpatClick}
        onHarvestClick={onHarvestClick}
        onMaintenanceClick={onMaintenanceClick}
        onFloatingManageClick={onFloatingManageClick}
        onExtraSeedClick={onExtraSeedClick}
        checkedHistoryView={checkedHistoryView}
        onHistoryViewClick={onHistoryViewClick}
      />
    ) : type === 'OYSTER' ? (
      <OysterLineMobile
        lineData={lineData as IOysterLineDetail}
        cyclePart={cyclePart as IOysterCycle}
        isPrevEnabled={isPrevEnabled}
        isNextEnabled={isNextEnabled}
        onClickPrevGroup={onClickPrevGroup}
        onClickNextGroup={onClickNextGroup}
        showEditLineModal={showEditLineModal}
        showEditSeedModal={showEditSeedModal}
        onSeedingClick={onSeedingClick}
        onAssessmentClick={onAssessmentClick}
        onCatchSpatClick={onCatchSpatClick}
        onHarvestClick={onHarvestClick}
        onMaintenanceClick={onMaintenanceClick}
        onExtraSeedClick={onExtraSeedClick}
        onFloatingManageClick={onFloatingManageClick}
        checkedHistoryView={checkedHistoryView}
        onHistoryViewClick={onHistoryViewClick}
      />
    ) : (
      <SeaweedLineMobile
        lineData={lineData as ISeaweedLineDetail}
        cyclePart={cyclePart as ISeaweedCycle}
        isPrevEnabled={isPrevEnabled}
        isNextEnabled={isNextEnabled}
        onClickPrevGroup={onClickPrevGroup}
        onClickNextGroup={onClickNextGroup}
        showEditLineModal={showEditLineModal}
        showEditSeedModal={showEditSeedModal}
        onSeedingClick={onSeedingClick}
        onAssessmentClick={onAssessmentClick}
        onCatchSpatClick={onCatchSpatClick}
        onHarvestClick={onHarvestClick}
        onMaintenanceClick={onMaintenanceClick}
        onExtraSeedClick={onExtraSeedClick}
      />
    )}
  </>
);

export default LineTemplateMobile;