import { useState, useMemo, useEffect } from 'react';
import { Modal } from 'antd';
import {
  Button,
  CloseIcon,
  Datepicker,
  Dropdown,
  Input,
  Subtitle,
  Spinner,
} from '../../components/shared';
import { useDispatch, useSelector } from 'react-redux';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';
import './styles.scss';
import FlupsySectionsModal from '../../components/view-modals/FlupsySectionsModal';
import { parse } from 'path';
import { showFeedback } from '../../store/ui/ui.actions';
import { sendSingleRequest } from '../../apis';
import ModalFeedbackView from '../../components/shared/feedback/ModalFeedbackView';
import { Link } from 'react-router-dom';

const basketsPerSectionsOptions = [
  { id: '4', label: '4', value: '4' },
  { id: '6', label: '6', value: '6' },
  { id: '8', label: '8', value: '8' },
];

interface Props {
  visible: boolean;
  onCancel: () => void;
}

interface BucketsData {
  lines: any;
  farm_id: number;
}

const FlupsyModal = ({ visible, onCancel }: Props) => {
  const dispatch = useDispatch<any>();
  const lang = useSelector(selectLang);
  const [basketketsPerSection, setBasketketsPerSection] = useState(0);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [backetWidth, setBacketWidth] = useState(0);
  const [backetHeight, setBacketHeight] = useState(0);
  const [showFluspySectionsModal, setShowFluspySectionsModal] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [bucketsData, setBucketsData] = useState<BucketsData | null>(null);
  const [existingData, setExistingData] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState<{
    source: string | null;
    condition: string | null;
    weight: string | null;
  }>({ source: null, condition: null, weight: null });

  useEffect(() => {
    fetchFlupsy();
  }, []);

  const fetchFlupsy = async () => {
    const response = await sendSingleRequest(
      {},
      'GET',
      'api/farm/get-fluspy',
      true,
    );
    if (response.status) {
      setExistingData(response.data);
      if(response.data.length === 0) {
        setShowCreateForm(true);
      }
    }
    setLoading(false);
  };

  const validForm = () => {
    if (![4, 6, 8].includes(basketketsPerSection)) {
      dispatch(
        showFeedback({
          type: 'error',
          isMessageModal: true,
          message: translate(
            lang,
            'Please select the number of buckets per section',
          ),
        }),
      );
      return null;
    }

    if (backetWidth < 1) {
      dispatch(
        showFeedback({
          type: 'error',
          isMessageModal: true,
          message: translate(lang, 'Please enter the height of the raft'),
        }),
      );
      return null;
    }

    if (backetHeight < 1) {
      dispatch(
        showFeedback({
          type: 'error',
          isMessageModal: true,
          message: translate(lang, 'Please enter the length of the raft'),
        }),
      );
      return null;
    }

    return true;
  };

  const confirmClick = async () => {
    const formData = validForm();
    if (!formData) return;
    setDisabled(true);
    const response = await sendSingleRequest(
      {
        buckets: basketketsPerSection,
        height: backetHeight,
        length: backetWidth,
      },
      'POST',
      'api/farm/create-fluspy',
      true,
    );

    if (response.status) {
      dispatch(
        showFeedback({
          isMessage: true,
          type: 'success',
          message: translate(lang, response.data?.message ?? 'Success'),
        }),
      );

      setBucketsData({
        lines: response.data?.lines,
        farm_id: response.data?.farm.id,
      });
      setShowFluspySectionsModal(true);
      setDisabled(false);
    } else {
      dispatch(
        showFeedback({
          isMessageModal: true,
          type: 'error',
          message: translate(lang, response.data?.message ?? 'Server error'),
        }),
      );
      setDisabled(false);
    }
  };

  const selectExisting = async (e: any, index: number) => {
    e.preventDefault();
    setBucketsData({
      lines: existingData[index].lines,
      farm_id: existingData[index].farm.id,
    });

    setBacketHeight(existingData[index].farm.metadata.height);
    setBacketWidth(existingData[index].farm.metadata['length']);
    setBasketketsPerSection(existingData[index].farm.metadata.buckets);
    setShowFluspySectionsModal(true);
  };

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      footer={null}
      closable
      closeIcon={<CloseIcon />}
    >
      <div className='wrap'>
        {loading ? (
          <div>
            <Spinner />
          </div>
        ) : (
          <>
            {existingData.length > 0 && (
              <>
                <div className='align-items-center mb-16'>
                  <Subtitle color='black-1' align='left' size={1} fontWeight={600}>
                    {translate(lang, 'Existing Flupsy')}
                  </Subtitle>
                </div>
                <div className='mb-32'>
                {existingData.map((data: any, index: number) => (
                    <div key={index} className='existing-flupsy'>
                        <button className='button button--3 button--green button--small button--bordered' onClick={(e) => selectExisting(e, index)} >{data.farm.name + ' - ' + data.farm.id}</button>
                    </div>
                ))}
                </div>
                {!showCreateForm &&
                  <Button
                    width={'small'}
                    size={2}
                    type='fill'
                    color='green'
                    className='rsp-btn ml-auto'
                    onClick={() => setShowCreateForm(true)}
                    disabled={disabled}
                  >
                  {translate(lang, 'Create New')}
                </Button>
                }
              </>
            )}
            {showCreateForm && (<> 
              <div className='d-flex align-items-center mb-32'>
                <Subtitle color='black-1' align='left' size={1} fontWeight={600}>
                  {translate(lang, 'Bay Spat Storage')}
                </Subtitle>
              </div>
              <div className='spat-storage-modal'>
                <div
                  className={`mb-17 ${errors.condition ? 'invalid-form' : ''}`}
                >
                  <p>
                    <b>Section</b> is a section of your flupsy containint multiple
                    bucket. How many buckets you have per section?
                  </p>
                  <Dropdown
                    onChange={v => setBasketketsPerSection(parseInt(v))}
                    label={translate(lang, 'Select buckets')}
                    options={basketsPerSectionsOptions}
                    value={basketketsPerSection.toString()}
                  />
                  {errors.condition && (
                    <div className='invalid-feedback'>{errors.condition}</div>
                  )}
                </div>
                <div className={`mb-17`}>
                  <p>
                    How big is your raft in terms of sections where X is height
                    and Y is length. For instance 4x by 4y would give you 16
                    sections (remember each section also has multiple baskets)
                  </p>
                </div>
                <div className={`mb-17 ${errors.weight ? 'invalid-form' : ''}`}>
                  <Input
                    type='number'
                    label={translate(lang, 'Height')}
                    value={backetHeight.toString() ?? ''}
                    onChange={e => setBacketHeight(parseInt(e.target.value))}
                  />
                  {errors.weight && (
                    <div className='invalid-feedback'>{errors.weight}</div>
                  )}
                </div>
                <div className={`mb-17 ${errors.weight ? 'invalid-form' : ''}`}>
                  <Input
                    type='number'
                    label={translate(lang, 'Length')}
                    value={backetWidth.toString() ?? ''}
                    onChange={e => setBacketWidth(parseInt(e.target.value))}
                  />
                  {errors.weight && (
                    <div className='invalid-feedback'>{errors.weight}</div>
                  )}
                </div>
                <Button
                  width={'small'}
                  size={2}
                  type='fill'
                  color='green'
                  className='rsp-btn ml-auto'
                  onClick={() => confirmClick()}
                  disabled={disabled}
                >
                  {translate(lang, 'Confirm')}
                </Button>
              </div>
            </>)} 
          </>
        )}
      </div>
      {showFluspySectionsModal && (
        <FlupsySectionsModal
          bucketsX={backetWidth}
          bucketsY={backetHeight}
          divisions={basketketsPerSection}
          bucketsData={bucketsData}
          onCancel={() => setShowFluspySectionsModal(false)}
        />
      )}
      <ModalFeedbackView />
    </Modal>
  );
};

export default FlupsyModal;