import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Caret,
  DotsIcon,
  Pen,
  PositiveNegativeTitle,
  Subtitle,
} from '../../components/shared';
import { Dropdown, Menu, Spin } from 'antd';
import { IMusselLineDetail } from '../../entities/farms.entities';
import { IMusselCycle } from '../../entities/growing.entities';
import {
  amountDays,
  calcLineLastSize,
  getEstHarvestDate,
} from '../../lib/farm.helpers';
import {
  LineInventoriesLabel,
  SeedInventoriesLabel,
} from '../../components/CommonViews';
import { sendSingleRequest } from '../../apis';
import { loadFarmsData } from '../../store/farms/farms.actions';
import { showFeedback } from '../../store/ui/ui.actions';
import { useHistory } from 'react-router-dom';
import { defaultDateFormat } from '../../util/toggleSecondMillisecond';
import { formatNumber } from '../../entities/util-functions';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';
import {
  SelectIsEditable,
  SelectIsFarmable,
  SelectIsGrower,
} from '../../store/extra/extra.selector';
import { labelNumber, labelRange } from '../../lib/common.helpers';
import { useMemo } from 'react';
import './styles.scss';

interface Props {
  lineData: IMusselLineDetail;
  cyclePart?: IMusselCycle | null;
  isPrevEnabled: boolean;
  isNextEnabled: boolean;
  onClickPrevGroup?: () => void;
  onClickNextGroup?: () => void;
  showEditLineModal: () => void;
  showEditSeedModal: () => void;
  onSeedingClick: () => void;
  onAssessmentClick: () => void;
  onCatchSpatClick: () => void;
  onHarvestClick: () => void;
  onMaintenanceClick: () => void;
  onFloatingManageClick: () => void;
  onExtraSeedClick: () => void;
  checkedHistoryView: number;
  onHistoryViewClick: () => void;
}

const FlupsyMusselLineMobile = ({
  cyclePart,
  lineData,
  isPrevEnabled,
  isNextEnabled,
  onClickPrevGroup,
  onClickNextGroup,
  showEditLineModal,
  showEditSeedModal,
  onSeedingClick,
  onAssessmentClick,
  onCatchSpatClick,
  onHarvestClick,
  onMaintenanceClick,
  onFloatingManageClick,
  onExtraSeedClick,
  checkedHistoryView,
  onHistoryViewClick,
}: Props) => {
  const dispatch = useDispatch<any>();
  const history = useHistory();
  const lang = useSelector(selectLang);
  const isGrower = SelectIsGrower(lineData.farm_id);
  const isEditable = SelectIsEditable(lineData.farm_id);
  const isFarmable = SelectIsFarmable(lineData.farm_id);
  const mainSeed = cyclePart?.main_seed;
  const lastAssessment =
    cyclePart && cyclePart.assessments.length > 0
      ? cyclePart?.assessments.reduce((p, c) =>
          !p || p.assessment_date < c.assessment_date ? c : p,
        )
      : null;

  const longlineLength =
    cyclePart?.seedings.reduce((p, c) => p + c.line_length, 0) ?? 0;
      
  const { totalHarvested, totalSeeded, totalStorage } = useMemo(
      () => ({
        totalHarvested: lineData.growing_cycle?.total_harvested_amount ?? 0,
        totalSeeded: lineData.growing_cycle?.total_seeded_amount ?? 0,
        totalStorage: lineData.growing_cycle?.total_storage ?? 0,
      }),
      [lineData],
    );
  
  const menuClick = async (e: any) => {
    if (e.key === 'edit') {
      showEditLineModal();
    } else if (e.key === 'delete') {
      if (!window.confirm(translate(lang, 'Are you sure to delete this data?')))
        return;
      const res = await sendSingleRequest(
        {},
        'DELETE',
        `api/farm/line/lines/${lineData.id}`,
        true,
      );
      if (res.status) {
        await dispatch(loadFarmsData());
        history.replace(`/farms/${lineData.farm_id}`);
        dispatch(
          showFeedback({
            isMessage: true,
            type: 'success',
            message: translate(lang, 'Deleted successfully'),
          }),
        );
      } else {
        dispatch(
          showFeedback({
            isMessage: true,
            type: 'error',
            message: translate(lang, res.data?.message ?? 'Unknown error'),
          }),
        );
      }
    }
  };

  const lastHarvest =
    cyclePart && cyclePart.harvests.length > 0
      ? cyclePart?.harvests.reduce((p, c) =>
          p.complete_date > c.complete_date ? p : c,
        )
      : null;

  function getNewestDate() {
    const timestamps = [
        lastHarvest?.complete_date,
        lastAssessment?.assessment_date,
        mainSeed?.planned_date_seed,
      ].filter(date => date !== undefined && date !== null) as number[];
      console.log(timestamps);
      if (timestamps.length === 0) {
          return undefined;
      }

      return Math.max(...timestamps);
  }

  const lastActivityDate = getNewestDate();

  return (
    <>
      <div className='pt-16 pb-4'>
        <div className={'table-mobile__card'} onKeyDown={() => undefined}>
          <div className={'table-mobile__card-dots'}>
            {isEditable && (
              <div className='dropdown'>
                <Dropdown
                  overlay={
                    <Menu onClick={menuClick}>
                      <Menu.Item key='edit'>
                        {translate(lang, 'Edit')}
                      </Menu.Item>
                      <Menu.Item key='delete'>
                        {translate(lang, 'Delete')}
                      </Menu.Item>
                    </Menu>
                  }
                  placement='bottomRight'
                  trigger={['click']}
                >
                  <div>
                    <DotsIcon />
                  </div>
                </Dropdown>
              </div>
            )}
          </div>
          <div className='d-flex justify-content-between align-items-center'>
            <Subtitle size={5} color='black-3' align='left' fontWeight={600}>
              {translate(lang, 'Line - %s', lineData.line_name)}
            </Subtitle>
          </div>
          <div className='pt-16'>
            <div className='d-flex pb-23'>
              <div className='flex-basis-50'>
                  <Subtitle
                    size={3}
                    color='black'
                    align='left'
                    fontWeight={400}
                  >
                    {translate(lang, 'Date seeded')}
                  </Subtitle>
                  <>
                  {defaultDateFormat(
                    lineData.growing_cycle?.main_seed.planned_date_seed,
                    '-',
                  )}
                  </>
              </div>
              <div className='flex-basis-50 ml-24'>
                <Subtitle
                  size={3}
                  color='black'
                  align='left'
                  fontWeight={400}
                >
                  {translate(
                    lang,
                    mainSeed?.is_growing
                      ? 'Planned harvest date'
                      : 'Harvested date',
                  )}
                </Subtitle>
                <Subtitle size={4} color='black' align='left' fontWeight={500}>
                  {defaultDateFormat(
                    lastAssessment?.planned_date_harvest ??
                      mainSeed?.planned_date_harvest,
                    '-',
                  )}
                </Subtitle>
              </div>
            </div>
            <div className='d-flex pb-23'>
              <div className='flex-basis-100'>
                <Subtitle
                    size={3}
                    color='black'
                    align='left'
                    fontWeight={400}
                  >
                    {translate(lang, 'Batch size')}
                  </Subtitle>
                  <Subtitle size={4} color='black' align='left' fontWeight={500}>
                  {totalStorage !== 0 ?
                  <>
                    [{lineData.growing_cycle?.main_seed?.spat_storage?.id}] {lineData.growing_cycle?.main_seed?.spat_storage?.source}
                    - {`${formatNumber(
                          (totalSeeded / totalStorage) * 100,
                    )}%`}
                  </> : '-'}
                  </Subtitle>
              </div>
            </div>
            <div className='d-flex pb-23'>
              <div className='flex-basis-100'>
                <Subtitle
                  size={3}
                  color='black'
                  align='left'
                  fontWeight={400}
                >
                  {translate(lang, 'Batch left in Flupsy')}
                </Subtitle>
                <Subtitle size={4} color='black' align='left' fontWeight={500}>
                {totalSeeded !== 0 ?
                  <>
                  {`${formatNumber(
                          (totalHarvested / totalSeeded) * 100,
                  )}%`} [{totalSeeded} - {totalHarvested} {translate(lang, 'moved to farm')}]
                  </> : '-'}
                </Subtitle>
              </div>
            </div>
            <div className='d-flex pb-23'>
              <div className='flex-basis-50'>
                <Subtitle
                  size={3}
                  color='black'
                  align='left'
                  fontWeight={400}
                  className='mb-4'
                >
                  {translate(lang, 'Stage')}
                </Subtitle>
                <Subtitle size={4} color='black' align='left' fontWeight={500}>
                  {longlineLength > 0 &&
                    cyclePart?.seedings.map((x, i) => (
                      <div key={i}>
                        {`${formatNumber(
                          (x.line_length / longlineLength) * 100,
                        )}% ${translate(lang, 'stage')} ${
                          x.spat_storage?.stage ?? 1
                        }`}
                      </div>
                    ))}
                </Subtitle>
              </div>
              <div className='flex-basis-50 ml-24'>
                <Subtitle
                    size={3}
                    color='black'
                    align='left'
                    fontWeight={400}
                    className='mb-4'
                  >
                    {translate(
                      lang,
                      'Last checked',
                    )}
                  </Subtitle>
                  <Subtitle size={4} color='black' align='left' fontWeight={500}>
                    {lastActivityDate !== undefined ? defaultDateFormat(lastActivityDate, '-') : '-'}
                  </Subtitle>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='white-card pt-16 pl-12 pb-16 pr-12 mb-8'>
        <div className='d-flex justify-content-between'>
          <div className='w-100 d-flex justify-content-between align-items-center  white-card-small pt-3 pb-3'>
            <Button
              color='blue'
              size={3}
              width='default'
              type='transparent'
              className='mr-26'
              name='prev'
              onClick={onClickPrevGroup}
              disabled={!isPrevEnabled}
              onlyIconDisabled
            >
              <Caret color='#5A607F' direction='left' />
            </Button>
            <Subtitle
              size={4}
              color='black'
              align='left'
              fontWeight={500}
              disabled={!mainSeed}
            >
              {mainSeed?.season_name ?? translate(lang, 'Empty')}
            </Subtitle>
            <Button
              color='blue'
              size={3}
              width='default'
              type='transparent'
              className='ml-26'
              name='next'
              onClick={onClickNextGroup}
              disabled={!isNextEnabled}
              onlyIconDisabled
            >
              <Caret color='#5A607F' direction='right' />
            </Button>
          </div>
          <Button
            color='blue'
            size={0}
            width='default'
            type='bordered'
            className='ml-12'
            iconOnly
            onClick={showEditSeedModal}
            disabled={!isEditable || !mainSeed?.is_growing}
          >
            <Pen />
          </Button>
        </div>
        <div className='pt-16'>
          <>
            {mainSeed ? (
              <div className='d-flex flex-direction-col'>
                <button
                  className={`timeline-button${
                    checkedHistoryView === 1 ? ' --checked' : ''
                  }`}
                  onClick={onHistoryViewClick}
                >
                  {checkedHistoryView === -1 ? (
                    <div className='ml-17 mr-17'>
                      <Spin />
                    </div>
                  ) : (
                    translate(
                      lang,
                      checkedHistoryView === 1
                        ? 'Hide history'
                        : 'Show history',
                    )
                  )}
                </button>
                {mainSeed.is_growing ? (
                  <>
                    <Button
                      className='mb-8 mt-8'
                      color='blue'
                      size={1}
                      width='wide'
                      type='fill'
                      onClick={onFloatingManageClick}
                      disabled={!isFarmable}
                    >
                      {translate(lang, 'Inventory Management')}
                    </Button>
                    <Button
                      color='blue'
                      size={1}
                      width='wide'
                      type='fill'
                      onClick={onAssessmentClick}
                      disabled={!isFarmable}
                    >
                      {translate(lang, 'Add assessment')}
                    </Button>
                    {!mainSeed.is_catch_spat && (
                      <Button
                        className='mt-8'
                        color='blue'
                        size={1}
                        width='wide'
                        type='fill'
                        onClick={onExtraSeedClick}
                        disabled={!isFarmable}
                      >
                        {translate(lang, 'Add extra seed')}
                      </Button>
                    )}
                    <Button
                      className='mt-8'
                      color='green'
                      size={1}
                      width='wide'
                      type='fill'
                      onClick={onMaintenanceClick}
                      disabled={!isFarmable}
                    >
                      {translate(lang, 'Add maintenance')}
                    </Button>
                    <Button
                      color='blue'
                      size={1}
                      width='wide'
                      type='bordered'
                      className='mt-8'
                      onClick={onHarvestClick}
                      disabled={!isFarmable}
                    >
                      {translate(lang, 'Harvest Complete')}
                    </Button>
                  </>
                ) : (
                  <span></span>
                )}
              </div>
            ) : (
              <>
                {!isGrower && (
                  <Button
                    color='blue'
                    size={1}
                    width='wide'
                    type='fill'
                    onClick={onCatchSpatClick}
                    disabled={!isFarmable}
                  >
                    {translate(lang, 'Catch Spat')}
                  </Button>
                )}
                <Button
                  className='mt-10'
                  color='blue'
                  size={1}
                  width='wide'
                  type='fill'
                  onClick={onSeedingClick}
                  disabled={!isFarmable}
                >
                  {translate(lang, 'Seed the line')}
                </Button>
                <div className='d-flex justify-content-center align-items-center mt-10'>
                  <Subtitle
                    size={5}
                    color='black'
                    align='left'
                    fontWeight={400}
                  >
                    {translate(lang, 'Line empty for')}
                  </Subtitle>
                  <Subtitle
                    size={5}
                    color='black'
                    align='left'
                    fontWeight={600}
                    className='ml-4 mr-27'
                  >
                    {amountDays(lineData?.line_idle)}
                  </Subtitle>
                </div>
              </>
            )}
          </>
        </div>
      </div>
    </>
  );
};

export default FlupsyMusselLineMobile;