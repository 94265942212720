import { useState } from 'react';
import { Modal } from 'antd';
import { Button, CloseIcon, Dropdown, Input, Subtitle } from '../shared';
import ModalFeedbackView from '../shared/feedback/ModalFeedbackView';
import { useDispatch, useSelector } from 'react-redux';
import { showFeedback } from '../../store/ui/ui.actions';
import { isEmailFormat } from '../../util/validation';
import { sendSingleRequest } from '../../apis';
import { loadAutomationData } from '../../store/automation/automation.actions';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';

const periods = [
  { id: 't', value: 'today', label: 'Today' },
  { id: '7', value: 'last_7', label: 'Last 7 days' },
  { id: '30', value: 'last_30', label: 'Last 30 days' },
  { id: 'm', value: 'last_month', label: 'Last month' },
  { id: 'y', value: 'last_year', label: 'Last year' },
];

const types = [
  { id: 'f', value: 'farm', label: 'Farm Overview' },
  { id: 't', value: 'task', label: 'Task Report' },
  { id: 's', value: 'seeding', label: 'Seeding Report' },
  { id: 'h', value: 'harvest', label: 'Harvest Report' },
  { id: 'a', value: 'assessment', label: 'Assessment Report' },
  { id: 'b', value: 'budget', label: 'Budget Report' },
  { id: 'n', value: 'spat_nursery', label: 'Spat Nursery Report' },
  { id: 'k', value: 'spats_report', label: 'Spats Report' },
];

const freOptions = [
  { id: '1', value: '1', label: 'Daily' },
  { id: '7', value: '7', label: 'Weekly' },
];

interface IFormData {
  title: string;
  description: string;
  frequency: number;
  period: 'today' | 'last_7' | 'last_30' | 'last_month' | 'last_year';
  report_type:
    | 'farm'
    | 'task'
    | 'seeding'
    | 'harvest'
    | 'assessment'
    | 'spat_nursery'
    | 'budget';
  emails: string;
}

const defaultForm: IFormData = {
  title: '',
  description: '',
  period: 'today',
  frequency: 1,
  report_type: 'farm',
  emails: '',
};

interface Props {
  title?: string;
  visible: boolean;
  onClose: () => void;
}

const AutoReportModal = ({ title, visible, onClose }: Props) => {
  const dispatch = useDispatch<any>();
  const lang = useSelector(selectLang);

  const [disabled, setDisabled] = useState(false);
  const [formData, setFormData] = useState<IFormData>(defaultForm);

  const updateForm = (key: keyof IFormData, val: any) => {
    setFormData({ ...formData, [key]: val });
  };
  const showError = (message: string) =>
    dispatch(
      showFeedback({
        isMessageModal: true,
        type: 'error',
        message: translate(lang, message),
      }),
    );

  const confirmClick = async () => {
    if (!formData.emails) {
      showError('You should enter at least one email');
      return;
    }
    if (formData.emails.split(',').some(x => !isEmailFormat(x))) {
      showError('_invalid_emails');
      return;
    }
    setDisabled(true);
    const response = await sendSingleRequest(
      {
        ...formData,
        emails: formData.emails.split(','),
      },
      'POST',
      'api/automation/report',
      true,
    );
    if (response.status) {
      await dispatch(loadAutomationData());
      dispatch(
        showFeedback({
          isMessage: true,
          message: response.data?.message ?? 'Success',
          type: 'success',
        }),
      );
      onClose();
    } else {
      setDisabled(false);
      showError(response.data?.message ?? 'Unknown error');
    }
  };

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      footer={null}
      closable
      closeIcon={<CloseIcon />}
    >
      <div className='section-modal wrap'>
        <div className='d-flex align-items-center mb-16'>
          <Subtitle color='black-1' align='left' size={1} fontWeight={600}>
            {translate(lang, title ?? 'Automate report sending')}
          </Subtitle>
        </div>
        <div className='section'>
          <Input
            label={translate(lang, 'Who will receive?')}
            placeholder={translate(
              lang,
              'Please enter all emails of receivers separate by comma',
            )}
            className='mb-17'
            type='text'
            value={formData.emails}
            onChange={e => updateForm('emails', e.target.value)}
          />
        </div>
        <div className='section mt-7'>
          <div className='mb-17'>
            <Dropdown
              label={translate(lang, 'Report type')}
              value={formData.report_type}
              options={types}
              onChange={v => updateForm('report_type', v)}
            />
          </div>
          <div className='mb-17'>
            <Dropdown
              label={translate(lang, 'Frequency')}
              options={freOptions}
              value={formData.frequency.toString()}
              onChange={v => updateForm('frequency', Number(v))}
            />
          </div>
          <div className='mb-17'>
            <Dropdown
              label={translate(lang, 'Period')}
              value={formData.period}
              options={periods}
              onChange={v => updateForm('period', v)}
            />
          </div>
          <div className='mb-17'>
            <Input
              label={translate(lang, 'Title')}
              type='text'
              value={formData.title}
              onChange={e => updateForm('title', e.target.value)}
            />
          </div>
          <div className='mb-17'>
            <Input
              label={translate(lang, 'Comment')}
              type='textarea'
              value={formData.description}
              onChange={e => updateForm('description', e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className='modal-button d-flex justify-content-end align-items-center'>
        <Button
          width='small'
          className='rsp-btn'
          size={2}
          type='fill'
          color='red'
          onClick={onClose}
          disabled={disabled}
        >
          {translate(lang, 'Cancel')}
        </Button>
        <Button
          width='small'
          size={2}
          type='fill'
          color='green'
          className='rsp-btn ml-16'
          onClick={confirmClick}
          disabled={disabled}
        >
          {translate(lang, 'Confirm')}
        </Button>
      </div>
      <ModalFeedbackView />
    </Modal>
  );
};

export default AutoReportModal;
