import {
    BreadcrumbComponent,
    Button,
    Caret,
    Pen,
    Subtitle,
    Title,
  } from '../../components/shared';
  import useMenuHandler from '../../components/shared/tables/useMenuHandler';
  import getBreadcrumbMenu from '../../util/BreadcrumbMenu';
  import prevIcon from '../../images/prev-image.png';
  import nextIcon from '../../images/next-image.png';
  import { useSelector } from 'react-redux';
  import { Link } from 'react-router-dom';
  import LineTagButton from '../../components/lines/LineTagButton';
  import { defaultDateFormat } from '../../util/toggleSecondMillisecond';
  import { IOysterLineDetail } from '../../entities/farms.entities';
  import { IOysterCycle } from '../../entities/growing.entities';
  import { amountDays } from '../../lib/farm.helpers';
  import {
    LineInventoriesLabel,
    SeedInventoriesLabel,
  } from '../../components/CommonViews';
  import HarvestPredictButton from '../../components/lines/HarvestPredictButton';
  import { formatNumber } from '../../entities/util-functions';
  import { selectLang } from '../../store/ui/ui.selector';
  import { translate } from '../../lib/lang.helper';
  import { Spin } from 'antd';
  import {
    SelectIsEditable,
    SelectIsFarmable,
    SelectIsGrower,
    SelectOysterFarmsByFarmID,
  } from '../../store/extra/extra.selector';
  import { useState,useMemo } from 'react';
  import OysterLineProbModal from '../../components/farm-util/OysterLineProbModal';
  import { labelNumber, labelRange } from '../../lib/common.helpers';
  import DotsMenu from '../../components/shared/dropdown-menu/DotsMenu';
  
  interface Props {
    lineData: IOysterLineDetail;
    cyclePart: IOysterCycle | null;
    isPrevEnabled: boolean;
    isNextEnabled: boolean;
    onClickPrevGroup: () => void;
    onClickNextGroup: () => void;
    showEditLineModal: () => void;
    showEditSeedModal: () => void;
    onSeedingClick: () => void;
    onCatchSpatClick: () => void;
    onAssessmentClick: () => void;
    onMaintenanceClick: () => void;
    onHarvestClick: () => void;
    onExtraSeedClick: () => void;
    onFloatingManageClick: () => void;
    checkedHistoryView: number;
    onHistoryViewClick: () => void;
    loadLine: () => void;
  }
  
  const OysterLineDesktop = ({
    lineData,
    cyclePart: curCycle,
    isPrevEnabled,
    isNextEnabled,
    onClickPrevGroup,
    onClickNextGroup,
    showEditLineModal,
    showEditSeedModal,
    onSeedingClick,
    onCatchSpatClick,
    onAssessmentClick,
    onMaintenanceClick,
    onHarvestClick,
    onExtraSeedClick,
    onFloatingManageClick,
    loadLine,
    checkedHistoryView,
    onHistoryViewClick,
  }: Props) => {
    const lang = useSelector(selectLang);
    const isEditable = SelectIsEditable(lineData.farm_id);
    const isGrower = SelectIsGrower(lineData.farm_id);
    const isFarmable = SelectIsFarmable(lineData.farm_id);
    const { redirectToLine } = useMenuHandler();
    const curFarm = SelectOysterFarmsByFarmID(lineData.farm_id).find(
      x => x.id === lineData.farm_id,
    );
    const lines = curFarm?.lines ?? [];
    const breadcrumbItems = getBreadcrumbMenu('FARM_LINE', {
      FARM_ID: lineData.farm_id,
      LINE_ID: lineData.id,
      FARM_NAME: lineData?.farm_name,
      LINE_NAME: lineData?.line_name,
    }).slice(0, -1);
    
  
    const curL = lines.findIndex(x => x.id === lineData.id);
    const prevL = (curL - 1 + lines.length) % lines.length;
    const nextL = (curL + 1) % lines.length;
  
    const mainSeed = curCycle?.main_seed,
      lastAssessment =
        curCycle && curCycle.assessments.length > 0
          ? curCycle.assessments.reduce((p, c) =>
              !p || p.assessment_date < c.assessment_date ? c : p,
            )
          : null;
    const growingBaskets = lineData.growing_cycle
      ? lineData.growing_cycle.current_basket_count
      : 0;
    const basketSum =
      curCycle?.seedings.reduce((p, c) => p + c.basket_count, 0) ?? 0;
  
    const lastHarvest =
      curCycle && curCycle.harvests.length > 0
        ? curCycle?.harvests.reduce((p, c) =>
            p.complete_date > c.complete_date ? p : c,
          )
        : null;
  
    const { totalHarvested, totalSeeded, totalStorage } = useMemo(
        () => ({
          totalHarvested: lineData.growing_cycle?.total_harvested_amount ?? 0,
          totalSeeded: lineData.growing_cycle?.total_seeded_amount ?? 0,
          totalStorage: lineData.growing_cycle?.total_storage ?? 0,
        }),
        [lineData],
      );
  
    const [visibleProb, setVisibleProb] = useState(false);
  
    function getNewestDate() {
      const timestamps = [
          lastHarvest?.complete_date,
          lastAssessment?.assessment_date,
          mainSeed?.planned_date_seed,
        ].filter(date => date !== undefined && date !== null) as number[];
        console.log(timestamps);
        if (timestamps.length === 0) {
            return undefined;
        }
  
        return Math.max(...timestamps);
    }
  
    const lastActivityDate = getNewestDate();
    
    return (
      <div className='farm-line-desktop-template'>
        <div className='pt-28 pb-28 d-flex justify-content-between align-items-center'>
          <div className='d-flex' style={{ alignItems: 'baseline' }}>
            <BreadcrumbComponent items={breadcrumbItems} />
            {lines && (
              <div className='ml-17'>
                <span
                  className='prev-link'
                  onClick={() =>
                    redirectToLine(lineData.farm_id, lines[prevL].id)
                  }
                >
                  <img src={prevIcon} alt='prev' />
                </span>
                <span className='line-name'>
                  {translate(lang, 'Line %s', lineData.line_name)}
                </span>
                <span
                  className='next-link'
                  onClick={() =>
                    redirectToLine(lineData.farm_id, lines[nextL].id)
                  }
                >
                  <img src={nextIcon} alt='next' />
                </span>
              </div>
            )}
          </div>
          <div className='d-flex justify-content-between align-items-center'>
            <div className='d-flex justify-content-between align-items-center white-card-small pt-3 pb-3'>
              <Button
                color='blue'
                size={3}
                width='default'
                type='transparent'
                className='mr-26'
                name='prev'
                onClick={onClickPrevGroup}
                disabled={!isPrevEnabled}
                onlyIconDisabled
              >
                <Caret color='#5A607F' direction='left' />
              </Button>
              <Subtitle
                size={4}
                color='black'
                align='left'
                fontWeight={500}
                disabled={!curCycle}
              >
                {mainSeed?.season_name ?? translate(lang, 'Empty')}
              </Subtitle>
              <Button
                color='blue'
                size={3}
                width='default'
                type='transparent'
                className='ml-26'
                name='next'
                onClick={onClickNextGroup}
                disabled={!isNextEnabled}
                onlyIconDisabled
              >
                <Caret color='#5A607F' direction='right' />
              </Button>
            </div>
            <Button
              color='blue'
              size={0}
              width='default'
              type='bordered'
              className='ml-8'
              iconOnly
              onClick={showEditSeedModal}
              disabled={!mainSeed?.is_growing || !isEditable}
            >
              <Pen />
            </Button>
            <Button
              color='blue'
              size={1}
              width='middle'
              type='bordered'
              className='ml-16'
              onClick={onHarvestClick}
              disabled={!mainSeed?.is_growing || !isFarmable}
            >
              {translate(lang, 'Harvest Complete')}
            </Button>
          </div>
        </div>
        <div className='white-card pt-28 pr-16 pb-28 pl-24 mb-16'>
          <div className='d-flex justify-content-between'>
            <div>
              <Title size={5} color='black-3' align='default' fontWeight={500}>
                {translate(lang, 'Line %s', lineData.line_name)}
              </Title>
              {!!lineData.growing_cycle?.last_harvest ? (
                <div style={{ marginTop: '20px' }}>
                  <Link
                    to={`/harvest/${lineData.growing_cycle.last_harvest.id}`}
                    style={{
                      fontStyle: 'italic',
                      textDecorationLine: 'underline',
                    }}
                  >
                    <Subtitle
                      size={5}
                      color='gray'
                      align='default'
                      fontWeight={400}
                    >
                      {translate(lang, 'Line is partially harvested')}
                    </Subtitle>
                  </Link>
                  <Subtitle
                    size={5}
                    color='#000'
                    align='default'
                    fontWeight={500}
                  >
                    {translate(
                      lang,
                      'Harvested %s so far',
                      `${lineData.growing_cycle.total_harvested_basket_count} ${lineData.farming_method}`,
                    )}
                  </Subtitle>
                </div>
              ) : (
                !!lineData.growing_cycle?.last_harvest && (
                  <div style={{ marginTop: '10px' }}>
                    <Link
                      to={`/harvest/${lineData.growing_cycle.last_harvest.id}`}
                      style={{
                        fontStyle: 'italic',
                        textDecorationLine: 'underline',
                      }}
                    >
                      <Subtitle
                        size={5}
                        color='gray'
                        align='default'
                        fontWeight={400}
                      >
                        {translate(lang, 'View last harvest')}
                      </Subtitle>
                    </Link>
                  </div>
                )
              )}
              <div className='mt-17'>
                <LineTagButton lineData={lineData} loadLine={loadLine} />
              </div>
            </div>
          <div>
                      <Subtitle
                        size={3}
                        color='black'
                        align='left'
                        fontWeight={400}
                        className='mb-4'
                      >
                        {translate(lang, 'Date seeded')}
                      </Subtitle>
                      <Subtitle size={4} color='black' align='left' fontWeight={500}>
                        {defaultDateFormat(mainSeed?.planned_date_seed, '-')}
                      </Subtitle>
                      <Subtitle
                        size={3}
                        color='black'
                        align='left'
                        fontWeight={400}
                        className='mb-4 mt-28'
                      >
                        {translate(lang, 'Spat Size')}
                      </Subtitle>
                      <Subtitle size={4} color='black' align='left' fontWeight={500}>
                        {lastAssessment
                          ? labelNumber(lastAssessment.shell_size.avg, 'mm', '-')
                          : labelRange(
                              mainSeed?.spat_size,
                              mainSeed?.spat_size_max ?? undefined,
                              'mm',
                              '-',
                            )}
                      </Subtitle>
                    </div>
                    <div>
                      <Subtitle
                        size={3}
                        color='black'
                        align='left'
                        fontWeight={400}
                        className='mb-4'
                      >
                        {translate(
                          lang,
                          mainSeed?.is_growing
                            ? 'Planned harvest date'
                            : 'Harvested date',
                        )}
                      </Subtitle>
                      <Subtitle size={4} color='black' align='left' fontWeight={500}>
                        {defaultDateFormat(
                          lastAssessment?.planned_date_harvest ??
                            mainSeed?.planned_date_harvest,
                          '-',
                        )}
                      </Subtitle>
                      <Subtitle
                        size={3}
                        color='black'
                        align='left'
                        fontWeight={400}
                        className='mb-4 mt-28'
                      >
                        {translate(lang, 'Batch size')}
                      </Subtitle>
                      <Subtitle size={4} color='black' align='left' fontWeight={500}>
                      {totalStorage !== 0 ?
                        <>
                          [{lineData.growing_cycle?.main_seed?.spat_storage?.id}] {lineData.growing_cycle?.main_seed?.spat_storage?.source}
                          - {`${formatNumber(
                                (totalSeeded / totalStorage) * 100,
                          )}%`}
                        </> : '-'}
                      </Subtitle>
                    </div>
                    <div>
                      <Subtitle
                        size={3}
                        color='black'
                        align='left'
                        fontWeight={400}
                        className='mb-4'
                      >
                        {translate(lang, 'Stage')}
                      </Subtitle>
                      <Subtitle size={4} color='black' align='left' fontWeight={500}>
                        {basketSum > 0 &&
                          curCycle?.seedings.map((x, i) => (
                            <div key={i}>
                              {`${formatNumber(
                                (x.basket_count / basketSum) * 100,
                              )}% ${translate(lang, 'seed stage') } ${i + 1}`}
                            </div>
                          ))}
                        {
                          curCycle?.harvests.map((x, i) => 
                            {
                              if (x.line_length) {
                                return (
                                  <div key={i}>
                                    {`${formatNumber(
                                      (x.line_length  / basketSum) * 100)
                                    }% ${translate(lang, 'harvest stage') }  ${i + 1}`}
                                  </div>
                                )
                              }
                            }
                          )
                        }
                      </Subtitle>
                      <Subtitle
                        size={3}
                        color='black'
                        align='left'
                        fontWeight={400}
                        className='mb-4 mt-28'
                      >
                        {translate(lang, 'Batch left in Flupsy')}
                      </Subtitle>
                      <Subtitle size={4} color='black' align='left' fontWeight={500}>
                      {totalSeeded !== 0 ?
                        <>
                        {`${formatNumber(
                                (totalHarvested / totalSeeded) * 100,
                        )}%`} [{totalSeeded} - {totalHarvested} {translate(lang, 'moved to farm')}]
                        </> : '-'}
                      </Subtitle>
                    </div>
                    <div>
                      <Subtitle
                          size={3}
                          color='black'
                          align='left'
                          fontWeight={400}
                          className='mb-4'
                        >
                          {translate(
                            lang,
                            'Last checked',
                          )}
                        </Subtitle>
                        <Subtitle size={4} color='black' align='left' fontWeight={500}>
                          {lastActivityDate !== undefined ? defaultDateFormat(lastActivityDate, '-') : '-'}
                        </Subtitle>
                    </div>
            {isEditable && (
              <DotsMenu
                items={[
                  {
                    label: translate(lang, 'Edit line'),
                    onClick: showEditLineModal,
                  },
                  {
                    label: translate(lang, 'Modify probability'),
                    onClick: () => setVisibleProb(true),
                  },
                ]}
              />
            )}
          </div>
          {!!lineData.growing_cycle?.predicted_date_harvest && (
            <div style={{ textAlign: 'right' }}>
              <HarvestPredictButton lineData={lineData} />
            </div>
          )}
        </div>
        <div className='d-flex justify-content-between align-items-center white-card pt-12 pr-16 pb-12 mb-8'>
          {!!mainSeed ? (
            <button
              className={`timeline-button${
                checkedHistoryView === 1 ? ' --checked' : ''
              }`}
              onClick={onHistoryViewClick}
            >
              {checkedHistoryView === -1 ? (
                <div className='ml-17 mr-17'>
                  <Spin />
                </div>
              ) : (
                translate(
                  lang,
                  checkedHistoryView === 1 ? 'Hide history' : 'Show history',
                )
              )}
            </button>
          ) : (
            <span></span>
          )}
          {isFarmable && (
            <div className='d-flex justify-content-end'>
              {mainSeed ? (
                <>
                  {mainSeed.is_growing ? (
                    <>
                      <Button
                        className='mr-10'
                        color='blue'
                        size={1}
                        width='middle'
                        type='fill'
                        onClick={onFloatingManageClick}
                      >
                        {translate(lang, 'Inventory Management')}
                      </Button>
                      <Button
                        className='mr-10'
                        color='green'
                        size={1}
                        width='middle'
                        type='fill'
                        onClick={onMaintenanceClick}
                      >
                        {translate(lang, 'Add maintenance')}
                      </Button>
                      {!mainSeed.is_catch_spat && (
                        <Button
                          color='blue'
                          size={1}
                          width='middle'
                          type='fill'
                          onClick={onExtraSeedClick}
                        >
                          {translate(lang, 'Add extra seed')}
                        </Button>
                      )}
                      <Button
                        className='ml-10'
                        color='green'
                        size={1}
                        width='middle'
                        type='fill'
                        onClick={onAssessmentClick}
                      >
                        {translate(lang, 'Add assessment')}
                      </Button>
                    </>
                  ) : (
                    <span></span>
                  )}
                </>
              ) : (
                <div className='d-flex align-items-center'>
                  <div className='d-flex align-items-center'>
                    <Subtitle
                      size={3}
                      color='black'
                      align='left'
                      fontWeight={400}
                    >
                      {translate(lang, 'Line empty for')}
                    </Subtitle>
                    <Subtitle
                      size={4}
                      color='black'
                      align='left'
                      fontWeight={600}
                      className='ml-4 mr-27'
                    >
                      {amountDays(lineData?.line_idle)}
                    </Subtitle>
                  </div>
                  {!isGrower && (
                    <Button
                      className='mr-8'
                      color='blue'
                      size={1}
                      width='small'
                      type='fill'
                      onClick={onCatchSpatClick}
                    >
                      {translate(lang, 'Catch Spat')}
                    </Button>
                  )}
                  <Button
                    className='mr-8'
                    color='blue'
                    size={1}
                    width='small'
                    type='fill'
                    onClick={onSeedingClick}
                  >
                    {translate(lang, 'Seed the line')}
                  </Button>
                  <Button
                    color='green'
                    size={1}
                    width='middle'
                    type='fill'
                    onClick={onMaintenanceClick}
                  >
                    {translate(lang, 'Add maintenance')}
                  </Button>
                </div>
              )}
            </div>
          )}
        </div>
        {visibleProb && (
          <OysterLineProbModal
            visible={true}
            lineID={lineData.id}
            onClose={() => setVisibleProb(false)}
          />
        )}
      </div>
    );
  };
  
  export default OysterLineDesktop;